import { CartLine, SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import {
    IDeliveryOptions,
    getDeliveryOptionsAsync,
    IDeliveryOption,
    DeliveryType
} from '../actions/farmlands-get-delivery-options-calls.action';
import { IActionContext } from '@msdyn365-commerce/core';

enum DeliveryMethodsAllowedValue {
    PICKUP = 'Pickup',
    DELIVERY = 'Delivery',
    PICKUP_OR_DELIVERY = 'PickupOrDelivery'
}

const DELIVERY_METHODS_ALLOWED_KEY = 'DeliveryMethodsAllowed';

export const getDeliveryMethodsAllowedProperty = (cartline: CartLine): DeliveryMethodsAllowedValue | undefined => {
    const extensionProperties = cartline?.ExtensionProperties;

    const deliveryMethodsAllowed = extensionProperties?.filter((keyValuePair) => {
        return keyValuePair.Key === DELIVERY_METHODS_ALLOWED_KEY;
    });

    return <DeliveryMethodsAllowedValue>deliveryMethodsAllowed?.[0]?.Value?.StringValue;
};

export const hasDeliveryOnlyError = (cartline: CartLine, deliveryOptions: IDeliveryOption[]): boolean => {
    // Check both extension property and the delivery options data to determine if cart line is delivery only.
    const hasDeliveryOptionOnly =
        hasDeliveryType(deliveryOptions, DeliveryType.DELIVERY) &&
        !hasDeliveryType(deliveryOptions, DeliveryType.PICKUP);


    return (
        (hasDeliveryOptionOnly ||
            getDeliveryMethodsAllowedProperty(cartline) === DeliveryMethodsAllowedValue.DELIVERY) &&
        cartline.FulfillmentStoreId !== undefined &&
        cartline.FulfillmentStoreId !== ''
    );
};

export const hasPickupOnlyError = (cartline: CartLine, deliveryOptions: IDeliveryOption[]): boolean => {
    // Check both extension property and the delivery options data to determine if cart line is pickup only.
    const hasPickupOptionOnly =
        hasDeliveryType(deliveryOptions, DeliveryType.PICKUP) &&
        !hasDeliveryType(deliveryOptions, DeliveryType.DELIVERY);


    return (
        (hasPickupOptionOnly || getDeliveryMethodsAllowedProperty(cartline) === DeliveryMethodsAllowedValue.PICKUP) &&
        (cartline.FulfillmentStoreId === undefined || cartline.FulfillmentStoreId === '')
    );
};

export const hasCartLineValidationErrors = (cartlines: CartLine[], deliveryOptions: IDeliveryOptions[]): boolean => {
    return (
        cartlines.filter((cartline) => {
            const deliveryOptionForCartLine = deliveryOptions.filter(
                (item) => item.ProductId === cartline.ItemId?.toString()
            )[0];
            return (
                hasDeliveryOnlyError(cartline, deliveryOptionForCartLine.DeliveryOptions) ||
                hasPickupOnlyError(cartline, deliveryOptionForCartLine.DeliveryOptions)
            );
        }).length > 0
    );
};

export const retrieveDeliveryOptionsForCartItems = async (
    actionContext: IActionContext,
    products: SimpleProduct[]
): Promise<IDeliveryOptions[]> => {
    if (products && products?.length > 0) {
        return Promise.all(
            products.map((product) =>
                getDeliveryOptionsAsync({
                    context: { callerContext: actionContext, queryResultSettings: {} },
                    recordId: product.RecordId,
                    salesPriceInclGST: product.AdjustedPrice
                }).then((response) => {
                    return response;
                })
            )
        );
    } else {
        return [];
    }
};

export const filterDeliveryOptionsForProductId = (
    deliveryOptions: IDeliveryOptions[],
    productId: string
): IDeliveryOptions => {
    return deliveryOptions.filter((deliveryOption) => {
        return deliveryOption.ProductId === productId.toString();
    })[0];
};

export const hasDeliveryType = (deliveryOptions: IDeliveryOption[], type: DeliveryType): boolean => {
    return (
        deliveryOptions && deliveryOptions.filter((deliveryOption) => {
            return deliveryOption.DeliveryType === type;
        }).length > 0
    );
};
