import {
    getDeliveryOptionsForSelectedVariant,
    GetDeliveryOptionsForSelectedVariantInput,
    getDimensionsForSelectedVariant,
    GetDimensionsForSelectedVariantInput,
    getPriceForSelectedVariant,
    getProductAvailabilitiesForSelectedVariant,
    getSelectedVariant,
    IProductInventoryInformation,
    PriceForSelectedVariantInput,
    ProductAvailabilitiesForSelectedVariantInput,
    SelectedVariantInput,
    // add import
    FinitePromiseQueue, IPromiseQueue, FinitePromiseQueueError
} from '@msdyn365-commerce-modules/retail-actions';
import { IModuleProps, INodeProps } from '@msdyn365-commerce-modules/utilities';
import { ProductDimensionFull } from '@msdyn365-commerce/commerce-entities';
import {
    ProductDeliveryOptions,
    ProductDimensionValue,
    ProductPrice,
    SimpleProduct
} from '@msdyn365-commerce/retail-proxy';
import classnames from 'classnames';
import * as React from 'react';
import { IFarmlandsBuyboxProps, IFarmlandsBuyboxResources } from './farmlands-buybox.props.autogenerated';
import { IFarmlandsBuyboxData } from './farmlands-buybox.data';
import {
    getBuyboxAddToCart,
    getBuyBoxInventoryLabel,
    getBuyboxProductAddToWishlist,
    getBuyboxProductConfigure,
    getBuyboxProductDescription,
    getBuyboxProductPrice,
    getBuyboxProductQuantity,
    getBuyboxProductRating,
    getBuyboxProductTitle,
    IBuyboxAddToCartViewProps,
    IBuyboxAddToWishlistViewProps,
    IBuyboxProductConfigureViewProps,
    IBuyboxProductQuantityViewProps
} from './components';
import { getContactDetailsAsync } from '../../actions/farmlands-get-contact-details-calls.action';
import {
    isShareholder,
    isShareholderAccountNotOnHold,
    isShareholderAccountNotInactive,
    isShareholderAccountInactive,
    hasEcommerceAccess
} from '../../common/method/functions';
import {
    getBuyboxFindInStore,
    IBuyboxFindInStoreViewProps
} from './components/buybox-find-in-store';
import {getEstimatedAvailabilityAsync} from "@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g";

export declare type IBuyboxErrorHost = 'ADDTOCART' | 'FINDINSTORE' | 'WISHLIST';

export interface IErrorState {
    errorHost?: IBuyboxErrorHost;

    configureErrors: { [configureId: string]: string | undefined };
    quantityError?: string;
    otherError?: string;
}

export interface IBuyboxCallbacks {
    updateQuantity(newQuantity: number): void;
    updateErrorState(newErrorState: IErrorState): void;
    updateSelectedProduct(
        selectedProduct: Promise<SimpleProduct | null>,
        newInventory: IProductInventoryInformation | undefined,
        newPrice: ProductPrice | undefined,
        newDeliveryOptions: ProductDeliveryOptions | undefined
    ): void;
    getDropdownName(dimensionType: number, resources: IFarmlandsBuyboxResources): string;
    dimensionSelectedAsync(selectedDimensionId: number, selectedDimensionValueId: string): Promise<void>;
    changeModalOpen(isModalOpen: boolean): void;
    changeUpdatingDimension(isUpdatingDimension: boolean): void;
}

export interface IBuyboxState {
    quantity: number;
    errorState: IErrorState;
    selectedDimensions: { [id: number]: string | undefined };
    selectedProduct?: Promise<SimpleProduct | null>;
    productAvailableQuantity?: IProductInventoryInformation;
    productPrice?: ProductPrice;
    productDeliveryOptions?: ProductDeliveryOptions;
    modalOpen?: boolean;
    hasEcomPermission?: boolean;
    isUpdatingDimension?: boolean;
    isCartEmpty: boolean;
    disableAddToCartButton: boolean;
}

export interface IBuyboxViewProps extends IFarmlandsBuyboxProps<IFarmlandsBuyboxData> {
    state: IBuyboxState;

    ModuleProps: IModuleProps;
    ProductInfoContainerProps: INodeProps;
    MediaGalleryContainerProps: INodeProps;

    callbacks: IBuyboxCallbacks;

    mediaGallery?: React.ReactNode;
    contentBlock?: React.ReactNode;

    title?: React.ReactNode;
    description?: React.ReactNode;
    rating?: React.ReactNode;
    price?: React.ReactNode;
    addToWishlist?: IBuyboxAddToWishlistViewProps | null;

    addToCart: IBuyboxAddToCartViewProps | null;
    findInStore?: IBuyboxFindInStoreViewProps;
    quantity?: IBuyboxProductQuantityViewProps;
    configure?: IBuyboxProductConfigureViewProps;
    inventoryLabel?: React.ReactNode;

    deliveryBlock?: any;
    clickCollectBlock?: any;
}

/**
 * Buybox Module
 */
class Buybox extends React.PureComponent<IFarmlandsBuyboxProps<IFarmlandsBuyboxData>, IBuyboxState> {
    /**
     * A queue of tasks of processing the changes in the dimensions.
     * Limit to two processes:
     * 1 - for the current process, which is under execution at the moment.
     * 2 - next process, which will process the latest version of data.
     * @remark Enqueueing new promises will discard the previous ones (except the one which is under processing).
     */
    private dimensionUpdateQueue: IPromiseQueue<void> = new FinitePromiseQueue<void>(2);
    private dimensions: { [id: number]: string } = {};


    private buyboxCallbacks: IBuyboxCallbacks = {
        updateQuantity: (newQuantity: number): void => {
            const errorState = { ...this.state.errorState };
            errorState.quantityError = undefined;
            errorState.otherError = undefined;

            this.setState({ quantity: newQuantity, errorState: errorState });
        },
        updateErrorState: (newErrorState: IErrorState): void => {
            this.setState({ errorState: newErrorState });
        },
        updateSelectedProduct: (
            newSelectedProduct: Promise<SimpleProduct | null>,
            newInventory: IProductInventoryInformation | undefined,
            newPrice: ProductPrice | undefined,
            newDeliveryOptions: ProductDeliveryOptions | undefined
        ): void => {
            this.setState({
                selectedProduct: newSelectedProduct,
                productAvailableQuantity: newInventory,
                productPrice: newPrice,
                productDeliveryOptions: newDeliveryOptions
            });
        },

        // Update this callback to use queue
        dimensionSelectedAsync: (selectedDimensionId: number, selectedDimensionValueId: string): Promise<void> => {
            const newSelectedDimensions: { [id: number]: string | undefined } = { ...this.state.selectedDimensions };
            newSelectedDimensions[selectedDimensionId] = selectedDimensionValueId;
            this.setState({ selectedDimensions: newSelectedDimensions });

            this.dimensions[selectedDimensionId] = selectedDimensionValueId;
            return this.dimensionUpdateQueue.enqueue(() => {
                return this._updateDimensions();
            }).catch((reason) => {
                // Ignore discarded processes.
                if (reason !== FinitePromiseQueueError.ProcessWasDiscardedFromTheQueue) {
                    throw reason;
                }
            });
        },
        getDropdownName: (dimensionType: number, resources: IFarmlandsBuyboxResources): string => {
            return this._getDropdownName(dimensionType, resources);
        },
        changeModalOpen: (isModalOpen: boolean): void => {
            this.setState({ modalOpen: isModalOpen });
        },
        changeUpdatingDimension: (isUpdatingDimension: boolean): void => {
            this.setState({ isUpdatingDimension: isUpdatingDimension });
        }
    };

    constructor(props: IFarmlandsBuyboxProps<IFarmlandsBuyboxData>, state: IBuyboxState) {
        super(props);
        this.state = {
            errorState: {
                configureErrors: {}
            },
            quantity: 1,
            selectedProduct: undefined,
            selectedDimensions: {},
            productPrice: undefined,
            productDeliveryOptions: undefined,
            hasEcomPermission: false,

            modalOpen: false,
            isUpdatingDimension: false,
            isCartEmpty: false,
            disableAddToCartButton: false
        };
    }

    public async componentDidMount(): Promise<void> {
        const cartLine = (await this.props.data.cart).cart.CartLines;
        // check if the logged-in user has ecomm purchase rights
        if (this.props.context.request.user.token) {
            const userContactDetails = await getContactDetailsAsync(
                { callerContext: this.props.context.actionContext, queryResultSettings: {} },
                JSON.parse(atob(this.props.context.request.user.token.split('.')[1])).oid
            );
            if (hasEcommerceAccess(userContactDetails)) {
                this.setState({
                    hasEcomPermission: true
                });
            }
        } else {
            this.setState({ hasEcomPermission: true });
        }
        if (cartLine && cartLine.length > 0) {
            this.setState({ isCartEmpty: true });
        }

    }

    public render(): JSX.Element | null {
        const {
            slots: { mediaGallery, contentBlock },
            data: {
                cart: { result: cart },
                product: { result: product }
            },
            config: {
                className = '',
                deliveryAvaliableIcon,
                clickCollectAvaliableIcon
            }
        } = this.props;
        if (!product) {
            this.props.context.telemetry.error('Product content is empty, module wont render');
            return null;
        }

        this._checkAddToCartDisable(this.props);

        const hideAddToCartButton = cart?.cart.ExtensionProperties
            ? isShareholder(cart?.cart) &&
            (!isShareholderAccountNotOnHold(cart.cart.ExtensionProperties) ||
                (!isShareholderAccountNotInactive(cart.cart.ExtensionProperties) &&
                    !isShareholderAccountInactive(cart.cart.ExtensionProperties)))
            : true;

        const viewProps: IBuyboxViewProps = {
            ...(this.props as IFarmlandsBuyboxProps<IFarmlandsBuyboxData>),
            state: this.state,
            mediaGallery: mediaGallery && mediaGallery.length > 0 ? mediaGallery[0] : undefined,
            contentBlock: contentBlock && contentBlock.length > 0 ? contentBlock[0] : undefined,
            ModuleProps: {
                moduleProps: this.props,
                className: classnames('ms-buybox', className)
            },
            ProductInfoContainerProps: {
                className: 'ms-buybox__content'
            },
            MediaGalleryContainerProps: {
                className: 'ms-buybox__media-gallery'
            },
            callbacks: this.buyboxCallbacks,
            title: getBuyboxProductTitle(this.props),
            description: getBuyboxProductDescription(this.props),
            configure: getBuyboxProductConfigure(this.props, this.state, this.buyboxCallbacks),
            // @ts-ignore
            findInStore: getBuyboxFindInStore(this.props, this.state, this.buyboxCallbacks),
            price: getBuyboxProductPrice(this.props),
            addToCart:
                this.state.hasEcomPermission && !hideAddToCartButton
                    ? getBuyboxAddToCart(this.props, this.state, this.buyboxCallbacks)
                    : null,
            addToWishlist:
                this.state.hasEcomPermission && !hideAddToCartButton
                    ? getBuyboxProductAddToWishlist(this.props, this.state, this.buyboxCallbacks)
                    : null,
            rating: !this.props.context.app.config.hideRating && getBuyboxProductRating(this.props),
            quantity: getBuyboxProductQuantity(this.props, this.state, this.buyboxCallbacks),
            inventoryLabel: getBuyBoxInventoryLabel(this.props),
            deliveryBlock: deliveryAvaliableIcon,
            clickCollectBlock: clickCollectAvaliableIcon
        };

        return this.props.renderView(viewProps) as React.ReactElement;
    }

    // Update _dimensionSelected to _updateDimensions
    // tslint:disable-next-line:max-func-body-length
    private _updateDimensions = async (): Promise<void> => {
        const {
            data: {
                product: { result: product },
                productDimensions: { result: productDimensions }
            },
            context: {
                actionContext,
                request: {
                    apiSettings: { channelId }
                }
            }
        } = this.props;
        // const { selectedDimensions } = this.state;

        if (!product || !productDimensions) {
            return;
        }

        const dimensionsToUpdate: { [id: number]: string } = { ...this.dimensions };
        this.setState({ isUpdatingDimension: true });

        // // Step 1: Update state to indicate which dimensions are selected
        // const newSelectedDimensions: { [id: number]: string | undefined } = { ...selectedDimensions };
        // newSelectedDimensions[selectedDimensionId] = selectedDimensionValueId;
        // this.setState({ selectedDimensions: newSelectedDimensions });

        // Step 2: Clear any errors indicating the dimension wasn't selected
        // if (this.state.errorState.otherError) {
        //     const clearOtherErrorState = {...this.state.errorState};
        //     clearOtherErrorState.otherError = undefined;
        //     this.setState({errorState: clearOtherErrorState});
        // }

        for (const key of Object.keys(dimensionsToUpdate)) {
            if (this.state.errorState.configureErrors[key]) {
                const errorState = { ...this.state.errorState };
                errorState.configureErrors[key] = undefined;

                this.setState({ errorState: errorState });
            }
        }

        // Step 3, Build the actually selected dimensions, prioritizing the information in state
        // over the information in data
        const mappedDimensions = productDimensions
            .map((dimension) => {
                return {
                    DimensionTypeValue: dimension.DimensionTypeValue,
                    DimensionValue: this._updateDimensionValue(dimension, dimensionsToUpdate[dimension.DimensionTypeValue]) || dimension.DimensionValue,
                    ExtensionProperties: dimension.ExtensionProperties
                };
            })
            .filter((dimension) => {
                return dimension && dimension.DimensionValue;
            });
        // Step 4. Use these dimensions hydrate the product. Wrap this in a promise
        // so that places like add to cart can await it
        const selectedProduct = new Promise<SimpleProduct | null>(async (resolve, reject) => {
            const newProduct = await getSelectedVariant(
                new SelectedVariantInput(
                    product.MasterProductId ? product.MasterProductId : product.RecordId,
                    channelId,
                    mappedDimensions
                ),
                actionContext
            );
            if (newProduct) {
                await getDimensionsForSelectedVariant(
                    new GetDimensionsForSelectedVariantInput(
                        newProduct.MasterProductId ? newProduct.MasterProductId : newProduct.RecordId,
                        channelId,
                        mappedDimensions
                    ),
                    actionContext
                );
            }

            resolve(newProduct);
        });
        this.setState({ selectedProduct: selectedProduct });
        const variantProduct = await selectedProduct;

        if (variantProduct) {
            // Step 5. Use these dimensions hydrate the inventory. Wrap this in a promise
            // so that places like add to cart can await it
            const newAvailableQuantity = await getProductAvailabilitiesForSelectedVariant(
                new ProductAvailabilitiesForSelectedVariantInput(variantProduct.RecordId, channelId),
                actionContext
            );

            if (newAvailableQuantity && newAvailableQuantity.length) {
                this.setState({ productAvailableQuantity: newAvailableQuantity[0] });
            } else {
                this.setState({ productAvailableQuantity: undefined });
            }

            // Step 6. Use these dimensions hydrate the product price.
            const newPrice = await getPriceForSelectedVariant(
                new PriceForSelectedVariantInput(variantProduct.RecordId, channelId),
                actionContext
            );

            if (newPrice) {
                this.setState({ productPrice: newPrice });
            }

            // Step 7. Use these dimensions hydrate the product delivery options.
            const newDeliveryOptions = await getDeliveryOptionsForSelectedVariant(
                new GetDeliveryOptionsForSelectedVariantInput(variantProduct.RecordId, channelId),
                actionContext
            );

            if (newDeliveryOptions) {
                this.setState({ productDeliveryOptions: newDeliveryOptions });
            }
        }
    };

     private _checkAddToCartDisable = async (props: any) => {
         //Custom code for work item #176444

         const {
             data: {
                 product: { result: product }
             }
         } = props;

         const searchCriteria = {
             DefaultWarehouseOnly: false,
             FilterByChannelFulfillmentGroup: true,
             ProductIds: [product.RecordId]
         }

         let physicalAvailableQuantity;
         let isBackorderAllowedProp;
         let isBackorderAllowed;

         const resp = await getEstimatedAvailabilityAsync({ callerContext: this.props.context.actionContext }, searchCriteria);

         physicalAvailableQuantity = resp.AggregatedProductInventoryAvailabilities![0].PhysicalAvailableQuantity;
         isBackorderAllowedProp = resp.AggregatedProductInventoryAvailabilities![0].ExtensionProperties?.find(item => item.Key === 'IsBackOrderAllowed');
         isBackorderAllowed = isBackorderAllowedProp?.Value?.BooleanValue;

         // Check if API returned value is null and set to 0 if true
         const physicalAvailableQuantityNullCheck = !physicalAvailableQuantity ? 0 : physicalAvailableQuantity;
         const finalCheck = physicalAvailableQuantityNullCheck! <= 0 && !isBackorderAllowed;

         this.setState({ disableAddToCartButton: finalCheck });
     }

    private _updateDimensionValue = (
        productDimensionFull: ProductDimensionFull,
        newValueId: string | undefined
    ): ProductDimensionValue | undefined => {
        if (newValueId && productDimensionFull.DimensionValues) {
            return productDimensionFull.DimensionValues.find((dimension) => dimension.RecordId === +newValueId);
        }

        return undefined;
    };

    private _getDropdownName = (dimensionType: number, resources: IFarmlandsBuyboxResources): string => {
        switch (dimensionType) {
            case 1: // ProductDimensionType.Color
                return resources.productDimensionTypeColor;
            case 2: // ProductDimensionType.Configuration
                return resources.productDimensionTypeConfiguration;
            case 3: // ProductDimensionType.Size
                return resources.productDimensionTypeSize;
            case 4: // ProductDimensionType.Style
                return resources.productDimensionTypeStyle;
            default:
                return '';
        }
    };
}

export default Buybox;
