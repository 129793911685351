import * as React from 'react';

import { format, Heading, INodeProps, Quantity } from '@msdyn365-commerce-modules/utilities';
import {
    AddToCartComponent,
    AddToWishlistComponent,
    IAddToCartFailureResult,
    IWishlistActionErrorResult,
    IWishlistActionSuccessResult,
    RatingComponent
} from '@msdyn365-commerce/components';
import { getUrlSync, IImageSettings } from '@msdyn365-commerce/core';
import { IAddToCartResources } from '@msdyn365-commerce/components/dist/types/index';
import { IFarmlandsBuyboxProps } from '../farmlands-buybox.props.autogenerated';
import { IBuyboxCallbacks, IBuyboxState } from '../farmlands-buybox';
import { IFarmlandsBuyboxData } from '../farmlands-buybox.data';
import { getConfigureErrors, getGenericError, getQuantityError } from '../utilities/error-utilities';
import { CartLine, CommerceProperty } from '@msdyn365-commerce/retail-proxy';


export function getBuyBoxInventoryLabel(props: IFarmlandsBuyboxProps<IFarmlandsBuyboxData>): React.ReactElement | undefined {
    const {
        data: {
            productAvailableQuantity: { result: productAvailableQuantity }
        }
    } = props;
    if (!productAvailableQuantity || !productAvailableQuantity.length) {
        return undefined;
    }
    if (productAvailableQuantity && productAvailableQuantity.length && productAvailableQuantity[0].StockLevelLabel) {
        const inventoryClassName = productAvailableQuantity[0].StockLevelCode
            ? `ms-buybox__inventory-label ms-buybox__inventory-code-${productAvailableQuantity[0].StockLevelCode.toLowerCase()}`
            : 'ms-buybox__inventory-label';
        return (
            <div className='ms-buybox__inventory-info'>
                <span className={inventoryClassName}>{productAvailableQuantity[0].StockLevelLabel}</span>
            </div>
        );
    }

    return undefined;
}
export function getBuyboxProductTitle(props: IFarmlandsBuyboxProps<IFarmlandsBuyboxData>): React.ReactElement | undefined {
    const {
        data: {
            product: { result: product }
        },
        config: { titleHeadingTag = 'h1' }
    } = props;

    return product && <Heading className='ms-buybox__product-title' headingTag={titleHeadingTag} text={product.Name || ''} />;
}

export function getBuyboxProductDescription(props: IFarmlandsBuyboxProps<IFarmlandsBuyboxData>): React.ReactElement | undefined {
    const {
        data: {
            product: { result: product }
        }
    } = props;

    return product && <p className='ms-buybox__product-description'>{product.Description}</p>;
}

export interface IBuyboxAddToCartViewProps {
    ContainerProps: INodeProps;
    errorBlock?: React.ReactNode;
    button?: React.ReactNode;
}
export function getBuyboxAddToCart(
    props: IFarmlandsBuyboxProps<IFarmlandsBuyboxData>,
    state: IBuyboxState,
    callbacks: IBuyboxCallbacks
): IBuyboxAddToCartViewProps {
    const {
        id,
        typeName,
        context,
        data: {
            cart: { result: cart },
            product: { result: product },
            productAvailableQuantity: { result: productAvailableQuantity }
        },
        resources
    } = props;
    const { quantity, selectedProduct, isUpdatingDimension, selectedDimensions } = state;
    const priceData = props.data?.product?.result?.ExtensionProperties;
    const priceIncGST = priceData!.find((prop: CommerceProperty) => prop.Key === 'BasePriceWithGST')?.Value?.DecimalValue;
    const specialPriceIncGST = priceData!.find((prop: CommerceProperty) => prop.Key === 'ItemPriceWithGST')?.Value?.DecimalValue;
    let { isCartEmpty } = state;
    const onAdd = () => {
        let productdetail = {
            productInfo: {
                productName: props.data.product.result?.Name,
                sku: props.data.product.result?.ItemId,
                price: props.data.product.result?.Price,
                productId: props.data.product.result?.RecordId
            },
            category: {
                primaryCategory: ''
            }
        };
        (Window as any).digitalData.product = [productdetail];
        if (!isCartEmpty) {
            window.dispatchEvent(new CustomEvent('CartOpen'));
            isCartEmpty = true;
        }
        window.dispatchEvent(new CustomEvent('AddToCart'));
    }
    const onAddToCartFailed = (result: IAddToCartFailureResult) => {
        callbacks.updateErrorState({
            errorHost: 'ADDTOCART',
            quantityError: result.failureReason === 'OUTOFSTOCK' ? getQuantityError(result.stockLeft, resources) : undefined,
            configureErrors: result.failureReason === 'MISSINGDIMENSION' ? getConfigureErrors(result.missingDimensions, resources) : {},
            otherError: getGenericError(result, cart, resources, context)
        });
    };

    const dialogStrings: IAddToCartResources = {
        goToCartText: resources.buyBoxGoToCartText,
        continueShoppingText: resources.buyBoxContinueShoppingText,
        headerItemOneText: resources.buyBoxSingleItemText,
        headerItemFormatText: resources.buyBoxMultiLineItemFormatText,
        headerMessageText: resources.buyBoxHeaderMessageText,
        freePriceText: resources.priceFree,
        originalPriceText: resources.originalPriceText,
        currentPriceText: resources.currentPriceText,
        closeNotificationLabel: resources.closeNotificationLabel,
        addedQuantityText: resources.addedQuantityText
    };

    const defaultImageSettings: IImageSettings = {
        viewports: {
            xs: { q: `w=240&h=240&m=6`, w: 0, h: 0 },
            lg: { q: `w=240&h=240&m=6`, w: 0, h: 0 },
            xl: { q: `w=240&h=240&m=6`, w: 0, h: 0 }
        },
        lazyload: true
    };

    const productAvailability = state.productAvailableQuantity
        ? state.productAvailableQuantity.ProductAvailableQuantity
        : productAvailableQuantity && productAvailableQuantity.length
            ? productAvailableQuantity[0].ProductAvailableQuantity
            : undefined;

    const isLoading = typeName !== 'quickview' && props.data.productAvailableQuantity.status === 'LOADING';
    if (isLoading) {
        callbacks.changeUpdatingDimension(false);
    }

    // Check product dimensions
    const productDim = props.data.product.result?.Dimensions?.find((dim) => !(dim.DimensionValue && dim.DimensionValue.DimensionId));

    // Check selected dimensions
    const allDim = selectedDimensions && Object.keys(selectedDimensions).length === props.data.productDimensions.result?.length;

    // Ensure custom API check is still compatible with pre-existing logic
    const disableButton = (state.disableAddToCartButton) || (allDim && productDim !== undefined);

    //Check the return values
    console.log(state.disableAddToCartButton);
    console.log(allDim);
    console.log(productDim);

    let addToCartText;
    //Fix GA issue
  //  if(disableButton) {
   //     addToCartText = resources.outOfStockText;
  //  } else {
        addToCartText = resources.addToCartText;
  //  }

    return {
        ContainerProps: {
            className: 'ms-buybox__add-to-cart-container'
        },
        button: product && (
            <AddToCartComponent
                addToCartText={addToCartText}
                outOfStockText={resources.outOfStockText}
                navigationUrl={getUrlSync('cart', context.actionContext)}
                quantity={quantity}
                data={{
                    product: product,
                    price: {
                        BasePrice: priceIncGST,
                        AdjustedPrice: specialPriceIncGST,
                        CustomerContextualPrice: specialPriceIncGST
                    }
                }}
                context={context}
                id={id}
                typeName={typeName}
                onError={onAddToCartFailed}
                onAdd={onAdd}
                getSelectedProduct={selectedProduct}
                productAvailability={productAvailability}
                isLoading={isLoading}
                disabled={disableButton}
                isUpdatingDimension={isUpdatingDimension}
                changeUpdatingDimension={callbacks.changeUpdatingDimension}
                dialogStrings={dialogStrings}
                gridSettings={props.context.request.gridSettings}
                imageSettings={defaultImageSettings}
            />
        )
    };
}

export function getBuyboxProductPrice(props: IFarmlandsBuyboxProps<IFarmlandsBuyboxData>): React.ReactElement | undefined {
    const priceData = props?.data.productPrice.result?.ExtensionProperties;
    const priceIncGST = priceData?.find((prop: CommerceProperty) => prop.Key === 'BasePriceWithGST')?.Value?.DecimalValue || 0;
    const specialPriceIncGST = priceData?.find((prop: CommerceProperty) => prop.Key === 'ItemPriceWithGST')?.Value?.DecimalValue || 0;
    const isOnSpecial = priceIncGST > specialPriceIncGST;
    return props ? (
        isOnSpecial ? (
            <div className='ms-buybox__price'>
                <span className='ms-buybox__price-original ms-buybox__price-original--strikethrough'>
                    ${Number(priceIncGST).toFixed(2)}
                </span>
                <span className='ms-buybox__price-discounted'>Your price ${Number(specialPriceIncGST).toFixed(2)}*</span>
            </div>
        ) : (
            <div className='ms-buybox__price ms-buybox__price-original'>${Number(priceIncGST).toFixed(2)}</div>
        )
    ) : undefined;
}

export function getBuyboxProductRating(props: IFarmlandsBuyboxProps<IFarmlandsBuyboxData>): React.ReactElement | undefined {
    const {
        id,
        typeName,
        context,
        data: {
            ratingsSummary: { result: ratingsSummary }
        },
        resources
    } = props;

    const ratingComponent =
        (ratingsSummary && ratingsSummary.averageRating && (
            <RatingComponent
                avgRating={ratingsSummary.averageRating || 0}
                readOnly={true}
                ariaLabel={format(resources.averageRatingAriaLabel, ratingsSummary.averageRating, '5')}
                ratingCount={`${ratingsSummary.reviewsCount}`}
                data={{}}
                context={context}
                id={id}
                typeName={typeName}
            />
        )) ||
        undefined;

    return ratingsSummary && ratingComponent && ratingComponent;
}

export interface IBuyboxAddToWishlistViewProps {
    ContainerProps: INodeProps;
    errorBlock?: React.ReactNode;
    button?: React.ReactNode;
}
export function getBuyboxProductAddToWishlist(
    props: IFarmlandsBuyboxProps<IFarmlandsBuyboxData>,
    state: IBuyboxState,
    callbacks: IBuyboxCallbacks
): IBuyboxAddToWishlistViewProps | undefined {
    const {
        id,
        typeName,
        context,
        data: {
            product: { result: product },
            wishlists: { result: wishlists }
        },
        resources
    } = props;

    const { selectedProduct } = state;
    const onAddToWishlistSuccess = (result: IWishlistActionSuccessResult, cartline: CartLine) => {
        if (result.status === 'ADDED') {
            window.dispatchEvent(new CustomEvent('AddToWishlist'));
        }
    }
    const onAddToWishlistFailed = (result: IWishlistActionErrorResult) => {
        callbacks.updateErrorState({
            errorHost: 'WISHLIST',
            configureErrors: result.status === 'MISSINGDIMENSION' ? getConfigureErrors(result.missingDimensions, resources) : {}
        });
    };

    return {
        ContainerProps: {
            className: 'ms-buybox__add-to-wishlist-container'
        },
        button: product && (
            <AddToWishlistComponent
                addToWishlistButtonText={resources.addToWishlistButtonText}
                removeFromWishlistButtonText={resources.removeFromWishlistButtonText}
                addToWishlistMessage={resources.addToWishlistMessage}
                removedFromWishlistMessage={resources.removedFromWishlistMessage}
                addItemToWishlistError={resources.addItemToWishlistError}
                removeItemFromWishlistError={resources.removeItemFromWishlistError}
                nameOfWishlist={resources.nameOfWishlist}
                data={{ product: product, wishlists: wishlists }}
                context={context}
                id={id}
                typeName={typeName}
                onSuccess={onAddToWishlistSuccess}
                onError={onAddToWishlistFailed}
                getSelectedProduct={selectedProduct}
            />
        )
    };
}

export interface IBuyboxProductQuantityViewProps {
    ContainerProps: INodeProps;
    LabelContainerProps: INodeProps;

    heading: React.ReactNode;
    errors?: React.ReactNode;

    input: React.ReactNode;
}
export function getBuyboxProductQuantity(
    props: IFarmlandsBuyboxProps<IFarmlandsBuyboxData>,
    state: IBuyboxState,
    callbacks: IBuyboxCallbacks
): IBuyboxProductQuantityViewProps {
    const {
        resources,
        context: {
            app: {
                config: { maxQuantityForCartLineItem }
            }
        }
    } = props;

    const {
        quantity,
        errorState: { quantityError }
    } = state;

    const onChange = (newValue: number) => {
        if (callbacks.updateQuantity) {
            callbacks.updateQuantity(newValue);
        }
    };

    return {
        ContainerProps: {
            className: 'ms-buybox__quantity'
        },
        LabelContainerProps: {
            tag: 'label',
            className: 'ms-buybox__product-quantity-label',
            htmlFor: 'ms-buybox__product-quantity-input'
        },
        heading: <div className='ms-buybox__product-quantity-label-heading'>{resources.productQuantityHeading}</div>,
        errors: quantityError && (
            <span className='msc-alert msc-alert-noborder msc-alert-danger'>
                <span className='msi-exclamation-triangle' aria-hidden='true' />
                <span>{quantityError}</span>
            </span>
        ),
        input: (
            <Quantity
                id='ms-buybox__product-quantity-input'
                max={maxQuantityForCartLineItem || 10}
                currentCount={quantity}
                onChange={onChange}
                inputQuantityAriaLabel={resources.inputQuantityAriaLabel}
            />
        )
    };
}
