/**
 * AttributeDataType enum type.
 */
export enum AttributeDataType {
    /**
     * The None member.
     */
    None = 0,
    /**
     * The Currency member.
     */
    Currency = 1,
    /**
     * The DateTime member.
     */
    DateTime = 2,
    /**
     * The Decimal member.
     */
    Decimal = 3,
    /**
     * The Integer member.
     */
    Integer = 4,
    /**
     * The Text member.
     */
    Text = 5,
    /**
     * The TrueFalse member.
     */
    TrueFalse = 6,
    /**
     * The Video member.
     */
    Video = 40,
    /**
     * The Image member.
     */
    Image = 41
}

/**
 * RefinerType enum type.
 */
export enum RefinerType {
    /**
     * The SingleSelect member.
     */
    SingleSelect = 0,
    /**
     * The MultiSelect member.
     */
    MultiSelect = 1,
    /**
     * The FreeText member.
     */
    FreeText = 2
}

/**
 * ProductRefinerSource enum type.
 */
export enum ProductRefinerSource {
    /**
     * The None member.
     */
    None = 0,
    /**
     * The Attribute member.
     */
    Attribute = 1,
    /**
     * The Category member.
     */
    Category = 2,
    /**
     * The Price member.
     */
    Price = 3,
    /**
     * The Rating member.
     */
    Rating = 4
}

/**
 * DisplayTemplate enum type.
 */
export enum DisplayTemplate {
    /**
     * The List member.
     */
    List = 0,
    /**
     * The Slider member.
     */
    Slider = 1,
    /**
     * The SliderWithBars member.
     */
    SliderWithBars = 2,
    /**
     * The Range member.
     */
    Range = 3,
    /**
     * The TextBox member.
     */
    TextBox = 4
}
