import { debounce } from 'lodash';
import {
    getESAMAddressSuggestions,
    IESAMAddressSuggestionsResult,
    IESAMFullAddressResult
} from '../actions/farmlands-esam-address-calls.action';

export const ESAM_EXTENSION_PROPERTY_KEY = 'FBHADDRESSVALIDATION';
export const CHECKOUT_ADDRESS_FLAG = 'CheckoutAddressFlag';

export interface IAddressModuleState {
    fullAddressResponse?: IESAMFullAddressResult;
    suggestionResults: IESAMAddressSuggestionsResult[] | null;
    selectedAddressValue: string;
    isManualEdit: boolean;
    isSearching: boolean;
}

/**
 * Sets or removes read only status on form inputs for an address form.
 * Relies on ids of form inputs to target.
 *
 * @param setAsReadOnly
 */
export const toggleReadOnlyFormInputs = (setAsReadOnly: boolean, inputIdList: string[]) => {
    inputIdList.forEach((id) => {
        const element = document.getElementById(id);
        if (setAsReadOnly) {
            element?.removeAttribute('readonly');
        } else {
            element?.setAttribute('readonly', 'true');
        }
    });
};

/**
 * Debounced function for calling API for address suggestions.
 */
export const debouncedESAMSuggestionsAPICall = debounce(
    (
        value: string,
        proxyUrl: string,
        subscriptionKey: string,
        onSuccessCallback: (response: IESAMAddressSuggestionsResult[]) => void
    ) => {
        getESAMAddressSuggestions(value, proxyUrl, subscriptionKey)
            .then((response) => {
                if (onSuccessCallback) {
                    onSuccessCallback(response!);
                }
            })
            .catch(() => {
                return;
            });
    },
    150
);

// Construct street value to save in address form based on ESAM full address response
export const constructStreetValue = (addressResponse: IESAMFullAddressResult) => {
    let address = '';
    if (addressResponse.AddressType === 'PO Box') {
        if (!addressResponse.AddressLine3) {
            return addressResponse.AddressLine1;
        }
        return `${addressResponse.AddressLine1}, ${addressResponse.AddressLine2}`;
    } else {
        if (addressResponse.AddressLine3) {
            address = `${addressResponse.AddressLine1}, ${addressResponse.AddressLine2}`;
        } else {
            address = `${addressResponse.AddressLine1}`;

            if (addressResponse.SuburbLocality) {
                address = `${address}, ${addressResponse.SuburbLocality}`;
            }

            if (addressResponse.UnitType) {
                address = `${address}, ${addressResponse.AddressLine2}`;
            }

            if (addressResponse.RuralDelivery) {
                address = `${address}, ${addressResponse.RuralDelivery}`;
            }
        }
    }
    return address;
};
