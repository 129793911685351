import { IStoreSelectorStateManager } from '@msdyn365-commerce-modules/bopis-utilities';
import { ICartState } from '@msdyn365-commerce/global-state';
import { ICoreContext } from '@msdyn365-commerce/core';
import {
    CartLine,
    OrgUnitLocation,
    ProductDeliveryOptions,
    SimpleProduct,
    ProductInventoryAvailability
} from '@msdyn365-commerce/retail-proxy';
import React, { useEffect } from 'react';
import {
    updateCartLinesAsync,
    updateDeliverySpecificationAsync
} from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';
import { getEstimatedProductWarehouseAvailabilityAsync } from '../../../actions/farmlands-get-estimated-product-warehouse-availability.action';
import { inventoryStocks } from '../inventoryStore';

// Note: BOPIS stands for "Buy Online, Pick-up In Store".
export interface IDeliveryMethodsProps {
    context: ICoreContext<{
        [x: string]: any;
    }>;
    cartState: ICartState;
    products: SimpleProduct[] | undefined;
    shipItText: string;
    pickUpInStoreText: string;
    changeStoreText: string;
    storeSelectorStateManager: IStoreSelectorStateManager | undefined;
    orgUnitLocations?: OrgUnitLocation[] | undefined;
    deliveryOptions?: ProductDeliveryOptions;
    pickupDeliveryModeCode?: string;
    showSpinner(showSpinner: boolean): void;
    data: any;
    config: any;
}

/**
 * Component for delivery options radio inputs at top of Order Summary in cart page.
 * Also handles much of the logic regarding pick-up in store functionality.
 */
// @ts-ignore
@observer
export const DeliveryMethods: React.FC<IDeliveryMethodsProps> = (props: IDeliveryMethodsProps): JSX.Element => {
    const {
        context,
        cartState,
        products,
        shipItText,
        pickUpInStoreText,
        changeStoreText,
        storeSelectorStateManager,
        orgUnitLocations,
        showSpinner
    } = props;
    const cartlines = cartState.cart.CartLines ?? [];
    const firstCartline = cartlines[0]; // Using first product for retrieving search results, as all stores should be returned regardless of the product.

    // const numberOfPickupLocationsSelected = (cartLines: CartLine[]) => {
    //     return cartLines.filter((cartline) => {
    //         return cartline.FulfillmentStoreId !== '';
    //     }).length;
    // };

    const updateCartLinePickupLocations = async (orgUnitLocation: OrgUnitLocation | undefined, onlyUpdateNonPickup: boolean = false) => {
        if (orgUnitLocation === undefined) {
            return;
        }

        for (const cartline of cartlines) {
            if ((onlyUpdateNonPickup && cartline.FulfillmentStoreId === '') || !onlyUpdateNonPickup) {
                await cartState.updateCartLinePickupLocation({ cartLineId: cartline.LineId!, location: orgUnitLocation })
                .catch((error: Error) => {
                    console.error('updateCartLinePickupLocations', error.message);
                    return;
                });
            }
        }
    };

    // @ts-ignore
    // React.useEffect(async() => {
    //     const numberOfPickUpInStoreItems = numberOfPickupLocationsSelected(cartlines);

    //     // Update pick-up locations if some (but not all) products have a pick up location already.
    //     if (numberOfPickUpInStoreItems > 0 && numberOfPickUpInStoreItems < cartlines.length) {
    //         showSpinner(true);
    //         await props.data.cart.result.refreshCart({});
    //         await _callUpdateDeliverySpecification(true, _getOrgUnit(firstCartline.FulfillmentStoreId, orgUnitLocations));
    //         await updateCartLinePickupLocations(_getOrgUnit(firstCartline.FulfillmentStoreId, orgUnitLocations), true)
    //             .then(() => {
    //                 showSpinner(false);
    //             })
    //             .catch((error) => {
    //                 return;
    //             });
    //     }
    // }, []);

    useEffect(async() => {
        await setDeliveryMode();
    }, []);

    const setDeliveryMode = async () => {
        showSpinner(true);

        inventoryStocks.lineItems = [];
        inventoryStocks.isClickCollect = false;

        for (const cartline of cartlines) {
            cartline.DeliveryMode = '';
            cartline.FulfillmentStoreId = '';
            cartline.ShippingAddress = {};
            await cartState
                .clearCartLinePickupLocation({ cartLineId: cartline.LineId! })
                .then((result) => {
                    if (result.status === 'SUCCESS') {
                        // do something
                    }
                })
                .catch((error: Error) => {
                    console.error('clearCartLinePickupLocation', error.message)
                    return;
                });
        }
        showSpinner(false);
        await _callUpdateDeliverySpecification(!!storeSelectorStateManager, undefined);
        //await setProductAttributes();
        getProductWarehouseAvailability(false);
    }

    const _getProduct = (productId: number | undefined): SimpleProduct | undefined => {
        if (productId !== undefined && products) {
            return products.find((product) => {
                return productId === product.RecordId;
            });
        }
        return undefined;
    };

    const _onLocationChangedCallback = async (orgUnitLocation: OrgUnitLocation) => {
        if (!cartState) {
            return Promise.resolve();
        }

        await updateCartLinePickupLocations(orgUnitLocation);
        await _callUpdateDeliverySpecification(!!!storeSelectorStateManager, orgUnitLocation);
        await getProductWarehouseAvailability(true);
    };

    const _callUpdateDeliverySpecification = async (isDelivery: boolean, orgUnitLocation?: OrgUnitLocation) => {
        const { deliveryModeIdDeliveryValue, deliveryModeIdPickupValue } = context.app.config;
        // Creating call to custom updateDeliverySpecification API to retain delivery method after changing quantity.
        const addressObject = {
            City: orgUnitLocation?.City || '',
            Name: orgUnitLocation?.OrgUnitName || '',
            Street: orgUnitLocation?.Street || '',
            ThreeLetterISORegionName: 'NZL',
            TwoLetterISORegionName: 'NZ',
            ZipCode: orgUnitLocation?.Zip || '',
            BuildingCompliment: '',
            County: orgUnitLocation?.Country || '',
            CountyName: orgUnitLocation?.CountyName || '',
            DistrictName: '',
            FullAddress: orgUnitLocation?.Address || '',
            Postbox: orgUnitLocation?.Postbox || '',
            RecordId: 0,
            State: '',
            StateName: '',
            StreetNumber: orgUnitLocation?.StreetNumber || ''
        };

        const updateCartDeliverySpecificationDeliveryInput = {
            DeliveryModeId: deliveryModeIdDeliveryValue,
            DeliveryPreferenceTypeValue: 1,
            DeliveryAddress: addressObject
        };

        const updateCartDeliverySpecificationPickupInput = {
            DeliveryModeId: deliveryModeIdPickupValue,
            DeliveryPreferenceTypeValue: 2,
            DeliveryAddress: addressObject,
            PickUpStoreId: orgUnitLocation?.OrgUnitNumber
        };

        const cookieCartId = context.actionContext.requestContext.cookies?.getCartCookie().substring(2);
        await updateDeliverySpecificationAsync(
            { callerContext: context.actionContext },
            cookieCartId,
            isDelivery ? updateCartDeliverySpecificationDeliveryInput : updateCartDeliverySpecificationPickupInput
        )
        .catch((error: Error) => {
            console.error('_callUpdateDeliverySpecification:', error.message);
        })
    };

    const _toggleBOPIS = async (isBopisSelected: boolean) => {
        if (isBopisSelected) {
            // If pick up in store option is picked, attempt to open store selector dialog...
            const product = _getProduct(firstCartline.ProductId);

            // Abort opening dialog if stateManager not present
            if (!storeSelectorStateManager) {
                return;
            }

            // Opening the store selector dialog requires a SINGLE product and not a list of products.
            // Need to find way to account for multiple products to search across in the store selector.
            storeSelectorStateManager
                .openDialog({
                    product,
                    alreadySelectedLocation: {
                        OrgUnitNumber: firstCartline.FulfillmentStoreId
                    },
                    onLocationSelected: (orgUnitLocation) => {
                        return _onLocationChangedCallback(orgUnitLocation);
                    }
                })
                .catch((error) => {
                    return;
                });
        } else {
            // Else, remove the location from the cart line.
            // This ideally should remove the location from all items.
            if (!cartState) {
                return;
            }

            await setDeliveryMode();
        }
    };

        const _getOrgUnit = (fulfillmentStoreId: string | undefined, locations: OrgUnitLocation[] | undefined) => {

            console.info('_getOrgUnit Method');
            console.info('_getOrgUnit Method fulfillmentStoreId', fulfillmentStoreId);
            console.log('_getOrgUnit Method OrgUnitLocation', locations);
          
        if (!locations || !fulfillmentStoreId || !(locations.length > 0)) {
            return undefined;
        }

        const foundLocation = locations.find((orgUnitLocation) => {
                return orgUnitLocation.OrgUnitNumber === fulfillmentStoreId;
        });

        if (foundLocation) {
            return foundLocation;
        } else {
            return undefined;
        }
    };
        console.info('FulfillmentStoreId:', firstCartline.FulfillmentStoreId);
        console.info('orgUnitLocations:', orgUnitLocations);
        console.info('orgUnitLocations:', orgUnitLocations?.length);

        const orgUnit = _getOrgUnit(firstCartline.FulfillmentStoreId, orgUnitLocations);

        console.log('orgUnit:', orgUnit);


    const bopisSelected = firstCartline.FulfillmentStoreId ? true : false;
    const toggleBOPIS = (isBopisSelected: boolean) => {
        return () => _toggleBOPIS(isBopisSelected);
    };

    const getProductWarehouseAvailability = async (isClickCollect: boolean) => {
        const {
            context,
            cartState
        } = props;

        await getEstimatedProductWarehouseAvailabilityAsync({
            context: { callerContext: context.actionContext },
            cartID: cartState.cart.Id
        })
        .then(async(response) => {
            inventoryStocks.lineItems = response.InventoryStockAvailabilities;
            inventoryStocks.isClickCollect = isClickCollect;

            await setProductAttributes();
        })
        .catch((error: Error) => {
            console.error('getProductWarehouseAvailability', error.message);
        });
    }

    const setProductAttributes = async () => {
        const {
            lineItems,
            isClickCollect
        } = inventoryStocks;
        const {
            cartLineOutOfStock,
            inventoryAvailabilityLevel,
            inventoryAvailabilityMessage,
            inventoryOrderOutOfStockFieldName,
            inventoryOrderOutOfStockMessage
        } = props.config;
        const cartlines = cartState.cart.CartLines ?? [];
        let newCartLine: Array<CartLine> = [];
        let newCartAttributes: Array<CartLine> = [{
                // @ts-ignore
                '@odata.type': '#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeTextValue',
                Name: inventoryOrderOutOfStockFieldName || 'inventoryOrderOutOfStockFieldName',
                TextValue: ''
        }];

        if (isClickCollect) {
            const hasOutOfStockProduct: boolean = lineItems.find((item: ProductInventoryAvailability) => item.PhysicalAvailableInventoryLevelCode === 'OOS') || false;
            if (hasOutOfStockProduct) {
                newCartAttributes = [
                    {
                        // @ts-ignore
                        '@odata.type': '#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeTextValue',
                        Name: inventoryOrderOutOfStockFieldName || 'inventoryOrderOutOfStockFieldName',
                        TextValue: inventoryOrderOutOfStockMessage || 'N/A'
                    }
                ];
            }
            cartlines.forEach((element: CartLine, index: number): void => {
                newCartLine.push({
                    ...element,
                    AttributeValues: [
                        {
                            // @ts-ignore
                            '@odata.type': '#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeTextValue',
                            Name: inventoryAvailabilityLevel || 'InventoryAvailabilityLevel',
                            // @ts-ignore
                            TextValue: isClickCollect ? lineItems[index]?.PhysicalAvailableInventoryLevelLabel : ''
                        },
                        {
                            // @ts-ignore
                            '@odata.type': '#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeTextValue',
                            Name: inventoryAvailabilityMessage || 'InventoryAvailabilityMessage',
                            // @ts-ignore
                            TextValue: isClickCollect && lineItems[index]?.PhysicalAvailableInventoryLevelCode === 'OOS' ? cartLineOutOfStock : ''
                        },
                    ]
                })
            });
        }
        else {
            cartlines.forEach((element: CartLine): void => {
                newCartLine.push({
                    ...element,
                    AttributeValues: []
                })
            });
        }

        await props.data.cart.result.refreshCart({});
        await _updateCart(newCartAttributes);
        await _updateCartLines(newCartLine);
    }

    const _updateCart = async (newCartAttributes: any) => {
        await props.data.cart.result?.updateCart({
            newCartObject: {
                ...props.data.cart.result?.cart,
                AttributeValues: newCartAttributes
            }
        }).catch((error: Error) => {
            console.error('_updateCart', error.message);
        });
    }

    const _updateCartLines = async (cartLines: any) => {
        const cookieCartId = context.actionContext.requestContext.cookies?.getCartCookie().substring(2)
        await updateCartLinesAsync(
            { callerContext: props.context.actionContext },
            cookieCartId,
            cartLines,
            props.data.cart.result?.cart?.Version!,
        ).catch((error: Error) => {
            console.error('_updateCartLines', error.message);
        });
    }

    return (
        <div className='msc-delivery-methods'>
            <label className='msc-delivery-method__option'>
                <input
                    id={`ms-cart-bopis-ship-option-${firstCartline.LineId}`}
                    className='msc-delivery-method__input'
                    type='radio'
                    name={`shippingType ${firstCartline.LineId}`}
                    onChange={toggleBOPIS(false)}
                    value={shipItText}
                    checked={!bopisSelected}
                    aria-checked={!bopisSelected}
                    key={`${firstCartline.LineId}-shipit`}
                />
                <div className='msc-delivery-method__radio-outer'>
                    <div className='msc-delivery-method__radio-inner' />
                </div>
                <i className='msc-delivery-method__icon material-icons-outlined'>local_shipping</i>
                {shipItText}
            </label>
            <label className='msc-delivery-method__option'>
                <input
                    id={`ms-cart-bopis-pickup-option-${firstCartline.LineId}`}
                    className='msc-delivery-method__input'
                    type='radio'
                    name={`shippingType ${firstCartline.LineId}`}
                    onChange={toggleBOPIS(true)}
                    value={pickUpInStoreText}
                    checked={bopisSelected}
                    aria-checked={bopisSelected}
                    key={`${firstCartline.LineId}-pickup`}
                />
                <div className='msc-delivery-method__radio-outer'>
                    <div className='msc-delivery-method__radio-inner' />
                </div>
                <i className='msc-delivery-method__icon material-icons'>storefront</i>
                {pickUpInStoreText}
            </label>
            <div
                className={`msc-delivery-method__selected-store${
                    firstCartline.FulfillmentStoreId && ' msc-delivery-method__selected-store--show'
                }`}
            >
                <span className='msc-delivery-method__selected-store-label'>Selected store:</span>
                <div className='msc-delivery-method__selected-store-details'>
                    <span className='msc-delivery-method__selected-store-detail msc-delivery-method__selected-store-detail--org-name'>
                        Farmlands {orgUnit?.OrgUnitName}
                    </span>
                    <span className='msc-delivery-method__selected-store-detail'>{orgUnit?.Address}</span>
                    <button className='msc-delivery-method__change-store' onClick={toggleBOPIS(true)}>
                        {changeStoreText}
                    </button>
                </div>
            </div>
        </div>
    );
};
